$gradient-start: rgba(158,217,204,1);
$gradient-end: rgba(60,115,102,1);

$menu-bar-height: 60px;
$entrepreneur-bar-width: 250px;

$font-size: 1rem;
$font-size-small: 0.75rem;
$font-size-x-small: 0.5rem;
$font-size-large: 1.25rem;
$font-size-x-large: 1.5rem;
$font-size-xx-large: 2rem;

%elevated-interface {
    box-shadow: 2px 2px 10px 1px lightgrey;
}

@keyframes processing-animation {
    from {font-size: $font-size-small; letter-spacing: 0; color: white;}
    to {font-size: 50px; letter-spacing: 40px; color: black;}
}

// ================ Transitions start ================
.transitionable {
    transition: transform 1s, opacity 1.5s;
}
.from-left {
    transform: translateX(-20%);
    opacity: 0;
}
.from-right {
    transform: translateX(20%);
    opacity: 0;
}
.from-top {
    transform: translateY(-50px);
    opacity: 0;
}
.from-bottom {
    transform: translateY(50px);
    opacity: 0;
}
.non-transitioned {
    transform: translateX(0);
    opacity: 1;
}
// ^^^^^^^^^^^^^^^^^ transitions end ^^^^^^^^^^^^^^^^^

* {
    box-sizing: border-box;
}

table {
    white-space: nowrap;
    border-collapse: collapse;
    & td {
        padding-left: 5px;
        padding-right: 5px;
    }
}

thead {
    font-weight: 700;
}

textarea {
    font-family: inherit;
    font-style: inherit;
    min-height: 1rem;
}

textarea:focus::placeholder, input:focus::placeholder {
    color: transparent;
}

select {
    outline: none;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: $font-size-small;
}

.hoverable {
    opacity: 0.3;
}

.hoverable:hover {
    opacity: 1;
}

.glass {
    background: rgba(158,217,204, .1);
    backdrop-filter: blur(4px);
    border-radius: .3rem;
    border: 1px solid rgba(158,217,204, .3);
}

.content-vertical {
    display: flex;
    flex-direction: column;
}

.content-horizontal {
    display: flex;
    flex-direction: row;
}

.content-centered {
    justify-content: center;
    align-items: center;
}

.section-height {
    min-height: calc(100vh - #{$menu-bar-height});
}

.title {
    font-size: $font-size-xx-large;
    color: $gradient-end;
}

.input-underlined {
    outline: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid black;
    background: transparent;
    margin: 5px;
    text-align: center;
}

.input-invisible {
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    background: transparent;
}

.increasing-text-animation {
    animation-name: processing-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.dropdown-menu {
    max-height: 40rem;
    overflow-y: auto;
}

#processing-progress {
    background-color: black;
    filter: opacity(0.6);
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    color: white;
}

#footer {
    display: flex;
    align-items: center;
    justify-content:space-between;

    & table {
        margin: 10px;

        & tbody > tr > td {
            padding: 10px;
        }
    }

    & a:link, a:visited, a:active {
        text-decoration: none;
        color: black;
        font-size: $font-size-large;
        font-weight: 200;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

/*
This media query is used for: iPhone 5/5s/SE
 */
@media only screen
and (device-width: 320px)
and (device-height: 568px) {}

/*
This media query is used for: iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X
 */
@media only screen
and (device-width: 375px)
and (device-height: 812px) {}

/*
This media query is used for: iPhone 13, iPhone 12 and iPhone 12 Pro
 */
@media only screen
and (device-width: 390px)
and (device-height: 844px) {}

/*
This media query is used for: iPhone 13 Pro Max and iPhone 12 Pro Max
 */
@media only screen
and (device-width: 428px)
and (device-height: 926px) {}

/*
This media query is used for: iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X
 */
@media only screen
and (device-width: 375px)
and (device-height: 812px) {}

/*
This media query is used for: iPhone 11 and iPhone XR
 */
@media only screen
and (device-width: 414px)
and (device-height: 896px)
and (-webkit-device-pixel-ratio: 2) {}

/*
Next 2 media queries are more or less universal
 */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 390px) {
    #footer {
        flex-direction: column;
        & .title {
            font-size: $font-size-large;
        }
    }
}

@media only screen
and (min-device-width: 390px)
and (max-device-width: 432px) {
    #footer {
        flex-direction: column;
        & .title {
            font-size: $font-size-large;
        }
    }
}